
import React, { useEffect, useState } from 'react'
import './FeedbackDashboard.scss'
import FeedbackDashboard from './FeedbackDashboard'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { Label, Button, ModalFooter } from 'reactstrap'
import ReschedulingFdbkForms from './ReschedulingFdbkForms.jsx'
import { routes } from '../../config/configs'
import Cookies from 'universal-cookie'
import axios from 'axios'

import {
  startFetchCourse,
  AddFeedbackQuestion,
  startFetchBatchCourse,
  startFetchBatchCourseChapters,
  StartFetchCourseFdbk,
} from '../../actions/courseAction.js'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function ViewSurveyResponses() {
  const cookies = new Cookies()

  const theme = useTheme()
  const [personName, setPersonName] = useState([])
  const [loader, setLoader] = useState(false)

  const [coursePageList, setCoursePageList] = useState([])
  const [selectedCoursePage, setSelectedCoursePage] = useState({})

  const [courseList, setCourseList] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({})

  const [chapterList, setChapterList] = useState([])
  const [selectedChapter, setSelectedChapter] = useState({})

  const [feedbackTypeList, setFeedbackTypeList] = useState([])
  const [selectedFeedbackType, setSelectedFeedbackType] = useState('')
  const [FeedbackArrayList, setFeedbackArrayList] = useState([])
  const [columns, setColumns] = useState([])


  useEffect(() => {
    ;(async () => {
      let res = await startFetchCourse()
      setCoursePageList([...res.data.courses])
    })()
  }, [])

  let handleSelectCoursePage = async (e) => {
    console.log(e.target.value)
    setSelectedCoursePage({ ...e.target.value })
    let coursePage = e.target.value
    if (coursePage._id) {
      let data = {
        crs_id: coursePage._id,
        limit: 100,
        skip: 0,
      }
      let batch_list = await startFetchBatchCourse(data)

      if (batch_list) {
        setCourseList([...batch_list.data.active_batches])
        setSelectedCourse({})
      }
    }
  }

 
  let handleSelectCourse = async (e) => {
    console.log(e.target.value)
    setSelectedCourse({ ...e.target.value })
    let course = e.target.value

    let queryURL = routes.BASE_URL_DEVELOPMENT + '/reports/dshbrd_rpts'
    var getData = {
      url: queryURL,
      method: 'POST',
      data: {
        filterCourse:selectedCoursePage.name,
        filterBatch:e.target.value.btch_name,
        repKey:"feedback",
      },
      headers: {
        'x-access-token': cookies.get('at'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
    axios(getData)
    .then((res) => {
      console.log(res.data.data)
      // if (!res.data.success) {
      //   alert(res.data.message)
      //   setLoader(false)
      //   return
      // }
      // if (res.data.data.dataForTable.length == 0) {
      //   alert('No Data found.')
      //   setLoader(false)
      //   return
      // }
      // setData(res.data.data)
      // setHeaders(res.data.data.columns)
      // setTotalPages(Math.floor(res.data.data.totalDataInQuery / tableSize))
      // setTotalDataInQuery(res.data.data.totalDataInQuery)

      // console.log(
      //   ' this is what we want to see ::: :',
      //   filterName !== 'Location' && filterName !== 'Batch'
      // )
      // if (
      //   res.data.data.locationFilterDropdown &&
      //   res.data.data.locationFilterDropdown.length > 0 &&
      //   filterName !== 'Location' &&
      //   filterName !== 'Batch' &&           filterName !== 'FeedbackName'

      // ) {
      //   console.log(
      //     ' ============ \n\n\n\n LocationDropdown : ',
      //     res.data.data.locationFilterDropdown
      //   )
      //   setLocationDropdown(res.data.data.locationFilterDropdown)
      // }
      // if (
      //   res.data.data.batchFilterDropdown &&
      //   res.data.data.batchFilterDropdown.length > 0 &&     filterName !== 'Batch' &&           filterName !== 'FeedbackName'
      // ) {
      //   console.log(
      //     ' ============ \n\n\n\n BatchDropdown : ',
      //     res.data.data.batchFilterDropdown
      //   )
      //   setBatchDropdown(res.data.data.batchFilterDropdown)
      // }
      // if (
      //   res.data.data.feedbackNameFilterDropdown &&
      //   res.data.data.feedbackNameFilterDropdown.length > 0 &&
      //   filterName !== 'FeedbackName'
      // ) {
      //   setFeedbackNameDropdown(res.data.data.feedbackNameFilterDropdown)
      // }
      setColumns([...res.data.data.columns[0].columns])
      setFeedbackArrayList([...res.data.data.dataForTable])

      setLoader(false)
      console.log(' ================ \n\n')
    })
    .catch((err) => {
      console.error(err)
      // alert("This was not supposed to happen! Please try again later.")
      setLoader(false)
    })

 
  }

  return (
    <div className='feedback-management'>
      <FeedbackDashboard />
      <div className="side-bar-tab-view">
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-chip-label">Select Program</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            value={selectedCoursePage.name}
            onChange={handleSelectCoursePage}
            input={
              <OutlinedInput id="select-multiple-chip" label="Select Program" />
            }
            renderValue={(selected) => {
              return selected.name
            }}
            MenuProps={MenuProps}
          >
            {coursePageList.map((coursePage) => (
              <MenuItem
                key={coursePage._id}
                value={coursePage}
                // style={getStyles(name, personName, theme)}
              >
                {coursePage.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedCoursePage && selectedCoursePage._id && (
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Select Batch</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              value={selectedCourse}
              onChange={handleSelectCourse}
              input={
                <OutlinedInput id="select-multiple-chip" label="Select Batch" />
              }
              renderValue={(selected) => {
                return selected.btch_name
              }}
              MenuProps={MenuProps}
            >
              {courseList.map((course) => (
                <MenuItem key={course._id} value={course}>
                  {course.btch_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
       

        <hr></hr>
        <div>
          <table class="table table-bordered">
            <thead>
              <tr>
                {columns.map((col)=>{
                  return(
                    <th
                    scope="col"
                    className="table-header"
                    style={{ textAlign: 'center' }}
                  >
                    {col.Header}
                  </th>
                  )
                })}
                {/* <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' }}
                >
                  Feedback Name
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' }}
                >
                  Program
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' }}
                >
                  Batch
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: ' -webkit-center' }}
                >
                  Chapter
                </th>

                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' }}
                >
                  Date & Time
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' }}
                >
                  Feedback Completion %
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' }}
                >
                  Feedback Type
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' }}
                >
                  Feedback reminder type
                </th> */}
                {/* <th></th> */}
              </tr>
            </thead>

            <tbody>
              {FeedbackArrayList &&
                FeedbackArrayList.map((data) => {
                  return (
                    <tr>
                      {Object.keys(data).map((dt,index)=>{
                        return(
                          <td>{data[columns[index].Header]}</td>

                        )
                      })}
                      {/* <td>{data.nm}</td>
                      <td>{selectedCourse.name}</td>
                      <td>{selectedCourse.btch_name}</td>
                      <td>
                        {selectedChapter.name ? selectedChapter.name : '-'}
                      </td>
                      <td>{data.createdAt}</td>
                      <td>-</td>
                      <td>{selectedFeedbackType}</td>
                      <td>{data.dism ? 'TRUE' : 'FALSE'}</td>
                      <td>
                        {' '}
                        <ReschedulingFdbkForms selectedCourse= {selectedCourse} data={data}/>
                      </td> */}
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ViewSurveyResponses

