import React, { useEffect, useState } from 'react'
import './FeedbackDashboard.scss'
import FeedbackDashboard from './FeedbackDashboard'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { Label, ModalFooter } from 'reactstrap'
import Button from '@mui/material/Button'

import ReschedulingFdbkForms from './ReschedulingFdbkForms.jsx'
import PreviewFeedback from './PreviewFeedback.jsx'

import {
  Autocomplete,
  TextField,
  Checkbox
} from '@mui/material'
import moment from 'moment'

import { Loader, Segment } from 'semantic-ui-react'

import {
  startFetchCourse,
  AddFeedbackQuestion,
  startFetchBatchCourse,
  startFetchBatchCourseChapters,
  StartFetchCourseFdbk,
  startFetchFeedbackScale,
  fetchFeedbackSection
} from '../../actions/courseAction.js'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function RescheduleFeedbackForms() {
  const theme = useTheme()
  const [personName, setPersonName] = useState([])
  const [loading, setLoading] = useState(false)

  const [coursePageList, setCoursePageList] = useState([])
  const [selectedCoursePage, setSelectedCoursePage] = useState({})

  const [courseList, setCourseList] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({})

  const [chapterList, setChapterList] = useState([])
  const [selectedChapter, setSelectedChapter] = useState({})

  const [feedbackTypeList, setFeedbackTypeList] = useState([])
  const [selectedFeedbackType, setSelectedFeedbackType] = useState('')
  const [FeedbackArrayList, setFeedbackArrayList] = useState([])
  const [feedbackPublishDetails, setFeedbackPublishDetails] = useState([])
  const [showPreviewFeedback, setShowPreviewFeedback] = useState(false)
  const [sectionArr, setSectionArr] = useState([])
  const [answerTemplateNameMap, setAnswerTemplateNameMap] = useState({})

  useEffect(() => {
    ; (async () => {
      setLoading(true)

      let res = await startFetchCourse()
      setCoursePageList([...res.data.courses])
      setLoading(false)

    })()
  }, [])

  let handleSelectCoursePage = async (crs) => {
    setLoading(true)
    console.log(crs)
    setSelectedCoursePage({ ...crs })
    console.log({ selectedCoursePage })
    let coursePage = crs
    if (coursePage._id) {
      let data = {
        crs_id: coursePage._id,
        limit: 100,
        skip: 0,
      }
      let batch_list = await startFetchBatchCourse(data)

      if (batch_list) {
        setCourseList([...batch_list.data.active_batches])
        setChapterList([])
        setFeedbackTypeList([])
        setSelectedFeedbackType('')
        setSelectedChapter({})
        setSelectedCourse({})
        setFeedbackArrayList([])
        setLoading(false)

      }
    }

  }

  let handleSelectChapter = async (chapter) => {
    setLoading(true)

    console.log(chapter)
    setSelectedChapter({ ...chapter })
    if (chapter) {
      let res = await StartFetchCourseFdbk(
        selectedCoursePage._id,
        selectedCourse._id,
        selectedFeedbackType,
        chapter._id
      )
      console.log({ res })
      if (res.data) {
        setFeedbackArrayList([...res.data.course_quizzes])
        setFeedbackPublishDetails([...res.data.feedback_publish_detail])
      }
    }
    setLoading(false)

  }
  let handleSelectFeedbackType = async (feedback) => {
    setLoading(true)
    console.log(feedback)

    setSelectedFeedbackType(feedback)
    let res = await StartFetchCourseFdbk(
      selectedCoursePage._id,
      selectedCourse._id,
      feedback,
      selectedChapter._id
    )
    console.log({ res })
    if (res.data) {
      setFeedbackArrayList([...res.data.course_quizzes])
      setFeedbackPublishDetails([...res.data.feedback_publish_detail])

    }
    setLoading(false)

  }
  let handleSelectCourse = async (batch) => {
    setLoading(true)

    console.log(batch)
    setSelectedCourse({ ...batch })
    let course = batch

    let res = await startFetchBatchCourseChapters({
      crs_pg_id: course.crs_pg_id,
      crs_id: course._id,
    })

    if (res.data) {
      setChapterList([...res.data.course_chapters])
      setFeedbackTypeList([...res.data.feedback_type])
      setSelectedChapter({})
      setFeedbackArrayList([])
      setSelectedFeedbackType('')
    }
    setLoading(false)
  }

    let handleShowPreviewFeeback = async (fdbk) => {
      console.log({fdbk})
      setLoading(true)
      let res = await startFetchFeedbackScale()
      if (res.data && res.data.feedback_scale) {
        setAnswerTemplateNameMap({ ...res.data.answer_template_map })
      }
      let section = await fetchFeedbackSection(selectedCoursePage._id, fdbk)
      if (section && section.data && section.data.find_section) {
        setSectionArr([...section.data.find_section])
      }
      setShowPreviewFeedback(true)
      setLoading(false)
    }

  return (
    <div style={{ backgroundColor: 'rgba(37, 92, 168, 0.05)', height: '100vh', overflowX: "hidden" }} className='feedback-management'>
      <FeedbackDashboard />
      {!loading && <div className="side-bar-tab-view">
        <div style={{ display: "flex", maxWidth: "1024px", justifyContent: "space-between" }}>
          <div style={{ width: "200px" }}>
            <Autocomplete
              style={{ width: "200px" }}
              className="select-course"
              disableClearable
              value={selectedCoursePage.name ? selectedCoursePage.name : 'Select a Course'}
              onChange={(e, crs) => {
                handleSelectCoursePage(crs)
              }}

              getOptionLabel={(course) => {
                return course.name || course
              }}
              getOptionSelected={(option, test) => option.name == test}
              options={coursePageList}
              disabled={coursePageList.length === 0 ? true : false}
              renderInput={(params) => {
                return (
                  <TextField
                    label={coursePageList.length == 0 ? 'Loading...' : ''}
                    {...params}
                    variant="outlined"
                    fullWidth
                  />
                )
              }}
            />
          </div>
          <div style={{ width: "200px" }}>

            {selectedCoursePage && selectedCoursePage._id && (
              // <FormControl sx={{ m: 1, width: 200 }}>
              //   <InputLabel id="demo-multiple-chip-label">Select Batch</InputLabel>
              //   <Select
              //     labelId="demo-multiple-chip-label"
              //     id="demo-multiple-chip"
              //     value={selectedCourse}
              //     onChange={handleSelectCourse}
              //     input={
              //       <OutlinedInput id="select-multiple-chip" label="Select Batch" />
              //     }
              //     renderValue={(selected) => {
              //       return selected.btch_name
              //     }}
              //     MenuProps={MenuProps}
              //   >
              //     {courseList.map((course) => (
              //       <MenuItem key={course._id} value={course}>
              //         {course.btch_name}
              //       </MenuItem>
              //     ))}
              //   </Select>
              // </FormControl>

              <Autocomplete
                style={{ width: "200px" }}
                className="select-course"
                disableClearable
                value={selectedCourse.btch_name ? selectedCourse.btch_name : 'Select a Batch'}
                onChange={(e, batch) => {
                  handleSelectCourse(batch)
                }}

                getOptionLabel={(batch) => {
                  return batch.btch_name || batch
                }}
                getOptionSelected={(option, test) => option.name == test}
                options={courseList}
                disabled={courseList.length === 0 ? true : false}
                renderInput={(params) => {
                  return (
                    <TextField
                      label={courseList.length == 0 ? 'Loading...' : ''}
                      {...params}
                      variant="outlined"
                      fullWidth
                    />
                  )
                }}
              />
            )}
          </div>
          <div style={{ width: "200px" }}>

            {selectedCourse && selectedCourse._id && (
            
              <Autocomplete
                style={{ width: "200px" }}
                className="select-course"
                disableClearable
                value={selectedFeedbackType ? selectedFeedbackType : 'Select a Feedback Type'}
                onChange={(e, feedback) => {
                  handleSelectFeedbackType(feedback)
                }}


                getOptionLabel={(feedback) => {
                  return feedback
                }}
                getOptionSelected={(option, test) => option.name == test}
                options={feedbackTypeList}
                disabled={feedbackTypeList.length === 0 ? true : false}
                renderInput={(params) => {
                  return (
                    <TextField
                      label={feedbackTypeList.length == 0 ? 'Loading...' : ''}
                      {...params}
                      variant="outlined"
                      fullWidth
                    />
                  )
                }}
              />

            )}
          </div>
          <div style={{ width: "200px" }}>

            {selectedCourse &&
              selectedCourse._id &&
              selectedFeedbackType != 'Survey Feedback' && (
                <Autocomplete
                  className="select-course"
                  style={{ width: "200px" }}
                  disableClearable
                  value={selectedChapter.nm ? selectedChapter.nm : 'Select a Chapter'}
                  onChange={(e, chapter) => {
                    handleSelectChapter(chapter)
                  }}

                  getOptionLabel={(chapter) => {
                    return chapter.nm || chapter
                  }}
                  getOptionSelected={(option, test) => option.name == test}
                  options={chapterList}
                  disabled={chapterList.length === 0 ? true : false}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={chapterList.length == 0 ? 'Loading...' : ''}
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
              )}
          </div>

        </div>

        <hr></hr>
        <div style={{width: '100%', height: '100%', background: 'white', borderRadius: 8, border: '1px rgba(60, 72, 82, 0.25) solid',overflowX: "scroll" }} >
          <table class="table table-bordered" >
            <thead>
              <tr className='tr-layout'>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' , minWidth:"130px"}}
                >
                  Feedback Name
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center', minWidth:"170px" }}
                >
                  Feedback Test Name
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center',minWidth:"70px" }}
                >
                  Program
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' ,minWidth:"50px" }}
                >
                  Batch
                </th>
                {selectedFeedbackType != 'Survey Feedback' && <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: ' -webkit-center'  ,minWidth:"70px"}}
                >
                  Chapter
                </th>}

                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' ,minWidth:"120px"}}
                >
                  Date & Time
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' ,minWidth:"200px"}}
                >
                  Feedback Completion %
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' ,minWidth:"150px"}}
                >
                  Feedback Type
                </th>
                <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' ,minWidth:"200px"}}
                >
                  Feedback Reminder Type
                </th>
                {selectedFeedbackType == 'Survey Feedback' && <th
                  scope="col"
                  className="table-header"
                  style={{ textAlign: 'center' ,minWidth:"200px"}}
                >
                  Reschedule Feedback
                </th>}
              </tr>
            </thead>

            <tbody>
              {FeedbackArrayList && selectedFeedbackType != 'Survey Feedback' &&
                FeedbackArrayList.map((data, i) => {
                  return (
                    <tr key={i}>
                      <td className="table-cell">{data.nm}</td>
                      {!data.is_old_feedback ? <td className="table-cell"> <Button  sx={{ textTransform: 'none' }} onClick={() => { handleShowPreviewFeeback(data.tid) }}> {data.test_name}</Button></td> :<td className="table-cell"> {data.test_name}</td> }

                      <td className="table-cell">{selectedCourse.name}</td>
                      <td className="table-cell">{selectedCourse.btch_name}</td>
                      {selectedFeedbackType != 'Survey Feedback' && <td className="table-cell">
                        {data.name ? data.name : '-'}
                      </td>}
                      <td className="table-cell"> {moment(data.createdAt).format(
                        'MMMM Do YYYY, h:mm:ss a'
                      )}</td>                          

                      <td className="table-cell">{data.comp_perc ? parseFloat(data.comp_perc.toFixed(2)) : 0}%</td>
                      <td className="table-cell">{selectedFeedbackType =='Module Feedback' ? 'Module Feedback' :(selectedFeedbackType =='Content Feedback'?'Content Feedback':selectedFeedbackType)}</td>
                      <td className="table-cell">{data.dism ? 'Dismissible' : 'Undismissable'}</td>
                    </tr>
                  )
                })}

        
              {feedbackPublishDetails && selectedFeedbackType == 'Survey Feedback' &&
                feedbackPublishDetails.map((data, i) => {
                  console.log({ data, selectedFeedbackType })
                  return (
                    <tr key={i}>
                      <td className="table-cell">{data.nm}</td>
                      {!data.is_old_feedback ? <td className="table-cell"> <Button  sx={{ textTransform: 'none' }} onClick={() => { handleShowPreviewFeeback(data.test_id) }}> {data.test_name}</Button></td> :<td className="table-cell"> {data.test_name}</td> }

                      <td className="table-cell">{selectedCourse.name}</td>
                      <td className="table-cell">{selectedCourse.btch_name}</td>
                      <td className="table-cell">
                        {moment(data.createdAt).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}
                      </td>
                      <td className="table-cell">{data.comp_perc ? parseFloat(data.comp_perc.toFixed(2)) : 0}%</td>
                      <td className="table-cell">Survey Feedback</td>
                      <td className="table-cell">{data.dism ? 'Dismissible' : 'Undismissable'}</td>
                      {data.p_type == 3?<td className="table-cell">
                        <ReschedulingFdbkForms selectedCourse={selectedCourse} data={data} />
                      </td>:<td>Only recurring feedback can be scheduled</td>}
                    </tr>
                  )
                })}

        
            </tbody>
          </table>

          {FeedbackArrayList && FeedbackArrayList.length == 0 && selectedFeedbackType != 'Survey Feedback' &&
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                  <div>
                    <img src="https://cdn.pegasus.imarticus.org/feedback/empty.png"></img>
                  </div>
                  <div></div>
                  <div>
                    <p className='empty-text-header'>Its empty here!</p>
                  </div>
                
                </div>
              }

            

              {feedbackPublishDetails && feedbackPublishDetails.length == 0 && selectedFeedbackType == 'Survey Feedback' &&
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                  <div>
                    <img src="https://cdn.pegasus.imarticus.org/feedback/empty.png"></img>
                  </div>
                  <div></div>
                  <div>
                    <p className='empty-text-header'>Its empty here!</p>
                  </div>
             
                </div>
              }
        </div>
        {showPreviewFeedback && <PreviewFeedback
              sectionArr={sectionArr}
              answerTemplateNameMap={answerTemplateNameMap}
              prevFeedbackTest={true}
              setShowPreviewFeedback={setShowPreviewFeedback}
            />}
      </div>}
      {
        loading && (
          <div className="Loader">
            <Loader active inline />
          </div>
        )
      }
    </div>
  )
}

export default RescheduleFeedbackForms
