import React, { useEffect, useState } from 'react'
import {
  Label,
  Input,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormText,
  ButtonGroup,
  Row,
  Col,
} from 'reactstrap'
import { Autocomplete, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import './FeedbackDashboard.scss'

import { Loader, Segment, Modal } from 'semantic-ui-react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import {
  startFetchCourse,
  startFetchAddedFeebackInCoursePage,
  cloneFeedback,
  createFeedbackForm,
  RescheduleFdbkForms,
} from '../../actions/courseAction.js'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import TextField from '@mui/material/TextField'

function ReschedulingFdbkForms(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [feedback, setFeedback] = useState({})
  const [coursePage, setCoursePage] = useState([])
  const [coursePageName, setCoursePageName] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedCrsPgId, setSelectedCrsPgId] = useState()
  const [sectionType, setSectionType] = useState(1)

  const [feedbackName, setFeedbackName] = useState('')

  const [schedulingPublishing, setSchedulingPublishing] = useState(1)
  const [timeZone, setTimeZone] = useState('Asia/Calcutta')
  const [startDt, setStartDt] = useState('')
  const [startHour, setStartHour] = useState('')
  const [startMin, setStartMin] = useState('')
  const [endDate, setEndDate] = useState('')
  const [endHour, setEndHour] = useState('')
  const [endMin, setEndMin] = useState('')
  const [course, setCourse] = useState({})

  console.log({ props })
  useEffect(() => {
    if (props.data) {
      setFeedback({ ...props.data })
      setCourse({ ...props.selectedCourse })
      if (props.data && !props.data.act) {
        setSchedulingPublishing(2)

      }

      setTimeZone(props.data.tz)
      let tz = props.data.tz
      const end_time = props.data.scdule_etim; // Your timestamp in milliseconds
      console.log({ tz })

      let formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: tz,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      });

      let parts = formatter.formatToParts(new Date(end_time));
      let dateParts = {
        year: parts.find(p => p.type === 'year')?.value,
        month: parts.find(p => p.type === 'month')?.value,
        day: parts.find(p => p.type === 'day')?.value,
        hour: parts.find(p => p.type === 'hour')?.value,
        minute: parts.find(p => p.type === 'minute')?.value,
      };

      // Save to variables
      let date = `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
      let hour = dateParts.hour;
      let minute = dateParts.minute;

      console.log(`Date: ${date}`);
      console.log(`Hour: ${hour}`);
      console.log(`Minute: ${minute}`);
      setEndDate(date)
      setEndHour(hour)
      setEndMin(minute)

      const start_time = props.data.scdule_tm; // Your timestamp in milliseconds

      formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: tz,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      });

      parts = formatter.formatToParts(new Date(start_time));
      dateParts = {
        year: parts.find(p => p.type === 'year')?.value,
        month: parts.find(p => p.type === 'month')?.value,
        day: parts.find(p => p.type === 'day')?.value,
        hour: parts.find(p => p.type === 'hour')?.value,
        minute: parts.find(p => p.type === 'minute')?.value,
      };

      // Save to variables
      date = `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
      hour = dateParts.hour;
      minute = dateParts.minute;

      console.log(`Date: ${date}`);
      console.log(`Hour: ${hour}`);
      console.log(`Minute: ${minute}`);

      setStartDt(date)
      setStartHour(hour)
      setStartMin(minute)



    }

  }, [])

  function convertTimeStringToParts(timeString) {
    let [hour, minute] = timeString.split(':'); // Split the string at ':'
    return { hour, minute }; // Convert to integers
  }

  function convertTimeStringToPartsforStartTime(timeString) {
    let [shour, sminute] = timeString.split(':'); // Split the string at ':'
    return { shour, sminute }; // Convert to integers
  }





  let handleTimeZone = (e) => {
    var tz = e.target.value
    setTimeZone(tz)
    console.log(tz)
  }
  let handleEndHour = (e) => {
    var tz = e.target.value
    setEndHour(tz)
    console.log(tz)
  }
  let handleEndMin = (e) => {
    var tz = e.target.value
    setEndMin(tz)
    console.log(tz)
  }

  let handleStartDate = (e) => {
    var st_dt = e.target.value
    setStartDt(st_dt)
  }

  let handleEndDate = (e) => {
    var et_dt = e.target.value
    setEndDate(et_dt)
  }
  let handleStartMin = (e) => {
    e.preventDefault()
    if ('stopPropagation' in e) {
      e.stopPropagation()
    }
    var startMin = e.target.value
    console.log(startMin)
    setStartMin(startMin)
  }

  let handleStartHour = (e) => {
    var et_dt = e.target.value
    setStartHour(et_dt)
  }

  let timeHandler = (date, hour, minute, second = 0, timeZone) => {
    console.log({ timeZone })
    let time = `${date} ${hour ? `${hour}` : ''}${minute ? `:${minute}` : ''
      }${second ? `:${second}` : ''}`
    console.log(time)
    let timestamp = +moment.tz(time, timeZone).format('x')
    console.log(timestamp)
    return timestamp
  }

  let handleSave = async () => {
    setLoading(true)
    let stim = timeHandler(startDt, startHour, startMin, 0, timeZone)
    let etim = timeHandler(endDate, endHour, endMin, 0, timeZone)

    let res = await RescheduleFdbkForms(course.crs_pg_id, course._id, feedback, startDt, stim, etim, schedulingPublishing, false)
    setLoading(false)
    setModalOpen(false)

    if ('statusCode' in res && res.statusCode == 403) {
      window.alert(res.error)
      setLoading(false)
      return
    }
    if (res.success) {
      window.alert('Saved Successfully')
      setLoading(false)
    }
    if (!res.success) {
      if (res.message) {
        window.alert(res.message)
      } else {
        window.alert('You have no permission to perform this action!')
      }
    }
  }

  return (
    <div className='feedback-management'>
      <Button
        className='resc-btn'
        outline
        onClick={() => {
          setModalOpen(true)
        }}
      >
        {' '}
        Reschedule Feedback
      </Button>

      {!loading && (
        <Modal
          style={{
            height: 'fit-content',
            margin: 'auto',
            marginTop: 'auto',
            width: '409px',
          }}
          className='feedback-management'

          open={modalOpen}
        >
          <Modal.Header>Reschedule Feedback   </Modal.Header>
          <Modal.Content
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            <div>
              <div>
                <Label for="exampleEmail">
                  <Label for="chapterName">Schedule Publishing</Label>

                </Label>
              </div>
              <div></div>
              <ButtonGroup>
                <Button
                  color={schedulingPublishing === 1 ? 'success' : 'secondary'}
                  style={{ margin: '0', width: '160px' }}
                  onClick={() => setSchedulingPublishing(1)}
                  active={schedulingPublishing === 1}
                >
                  Yes
                </Button>
                <Button
                  color={schedulingPublishing === 2 ? 'success' : 'secondary'}
                  style={{ margin: '0', width: '160px' }}
                  onClick={() => setSchedulingPublishing(2)}
                  active={schedulingPublishing === 2}
                >
                  No
                </Button>
              </ButtonGroup>
            </div>
            <br></br>
            {schedulingPublishing == 1 && <div>
              <div>
                <Label for="chapterName">Select TimeZone</Label>
                <Input
                  type="select"
                  name="type"
                  id="type"
                  placeholder="Assignment Type"
                  defaultValue={timeZone}
                  onChange={handleTimeZone}
                >
                  <option value="Asia/Calcutta">Asia/Calcutta (330)</option>
                  <option value="Pacific/Apia">Pacific/Apia (840)</option>
                  <option value="Pacific/Kiritimati">
                    Pacific/Kiritimati (840)
                  </option>
                  <option value="Pacific/Auckland">
                    Pacific/Auckland (780)
                  </option>
                  <option value="Pacific/Fiji">Pacific/Fiji (780)</option>
                  <option value="Pacific/Enderbury">
                    Pacific/Enderbury (780)
                  </option>
                  <option value="Pacific/Fakaofo">Pacific/Fakaofo (780)</option>
                  <option value="Pacific/Tongatapu">
                    Pacific/Tongatapu (780)
                  </option>
                  <option value="Asia/Kamchatka">Asia/Kamchatka (720)</option>
                  <option value="Pacific/Funafuti">
                    Pacific/Funafuti (720)
                  </option>
                  <option value="Pacific/Kwajalein">
                    Pacific/Kwajalein (720)
                  </option>
                  <option value="Pacific/Majuro">Pacific/Majuro (720)</option>
                  <option value="Pacific/Nauru">Pacific/Nauru (720)</option>
                  <option value="Pacific/Tarawa">Pacific/Tarawa (720)</option>
                  <option value="Pacific/Wake">Pacific/Wake (720)</option>
                  <option value="Pacific/Wallis">Pacific/Wallis (720)</option>
                  <option value="Australia/Hobart">
                    Australia/Hobart (660)
                  </option>
                  <option value="Australia/Sydney">
                    Australia/Sydney (660)
                  </option>
                  <option value="Pacific/Efate">Pacific/Efate (660)</option>
                  <option value="Pacific/Guadalcanal">
                    Pacific/Guadalcanal (660)
                  </option>
                  <option value="Pacific/Kosrae">Pacific/Kosrae (660)</option>
                  <option value="Pacific/Norfolk">Pacific/Norfolk (660)</option>
                  <option value="Pacific/Noumea">Pacific/Noumea (660)</option>
                  <option value="Pacific/Pohnpei">Pacific/Pohnpei (660)</option>
                  <option value="Australia/Adelaide">
                    Australia/Adelaide (630)
                  </option>
                  <option value="Antarctica/DumontDUrville">
                    Antarctica/DumontDUrville (600)
                  </option>
                  <option value="Asia/Magadan">Asia/Magadan (600)</option>
                  <option value="Asia/Vladivostok">
                    Asia/Vladivostok (600)
                  </option>
                  <option value="Australia/Brisbane">
                    Australia/Brisbane (600)
                  </option>
                  <option value="Pacific/Chuuk">Pacific/Chuuk (600)</option>
                  <option value="Pacific/Guam">Pacific/Guam (600)</option>
                  <option value="Pacific/Port_Moresby">
                    Pacific/Port_Moresby (600)
                  </option>
                  <option value="Australia/Darwin">
                    Australia/Darwin (570)
                  </option>
                  <option value="Asia/Dili">Asia/Dili (540)</option>
                  <option value="Asia/Jayapura">Asia/Jayapura (540)</option>
                  <option value="Asia/Seoul">Asia/Seoul (540)</option>
                  <option value="Asia/Tokyo">Asia/Tokyo (540)</option>
                  <option value="Asia/Yakutsk">Asia/Yakutsk (540)</option>
                  <option value="Pacific/Palau">Pacific/Palau (540)</option>
                  <option value="Asia/Pyongyang">Asia/Pyongyang (510)</option>
                  <option value="Antarctica/Casey">
                    Antarctica/Casey (480)
                  </option>
                  <option value="Asia/Brunei">Asia/Brunei (480)</option>
                  <option value="Asia/Choibalsan">Asia/Choibalsan (480)</option>
                  <option value="Asia/Hong_Kong">Asia/Hong_Kong (480)</option>
                  <option value="Asia/Irkutsk">Asia/Irkutsk (480)</option>
                  <option value="Asia/Kuala_Lumpur">
                    Asia/Kuala_Lumpur (480)
                  </option>
                  <option value="Asia/Macau">Asia/Macau (480)</option>
                  <option value="Asia/Makassar">Asia/Makassar (480)</option>
                  <option value="Asia/Manila">Asia/Manila (480)</option>
                  <option value="Asia/Shanghai">Asia/Shanghai (480)</option>
                  <option value="Asia/Singapore">Asia/Singapore (480)</option>
                  <option value="Asia/Taipei">Asia/Taipei (480)</option>
                  <option value="Asia/Ulaanbaatar">
                    Asia/Ulaanbaatar (480)
                  </option>
                  <option value="Australia/Perth">Australia/Perth (480)</option>
                  <option value="Antarctica/Davis">
                    Antarctica/Davis (420)
                  </option>
                  <option value="Asia/Bangkok">Asia/Bangkok (420)</option>
                  <option value="Asia/Hovd">Asia/Hovd (420)</option>
                  <option value="Asia/Jakarta">Asia/Jakarta (420)</option>
                  <option value="Asia/Krasnoyarsk">
                    Asia/Krasnoyarsk (420)
                  </option>
                  <option value="Asia/Saigon">Asia/Saigon (420)</option>
                  <option value="Indian/Christmas">
                    Indian/Christmas (420)
                  </option>
                  <option value="Asia/Rangoon">Asia/Rangoon (390)</option>
                  <option value="Indian/Cocos">Indian/Cocos (390)</option>
                  <option value="Antarctica/Vostok">
                    Antarctica/Vostok (360)
                  </option>
                  <option value="Asia/Almaty">Asia/Almaty (360)</option>
                  <option value="Asia/Bishkek">Asia/Bishkek (360)</option>
                  <option value="Asia/Dhaka">Asia/Dhaka (360)</option>
                  <option value="Asia/Omsk">Asia/Omsk (360)</option>
                  <option value="Asia/Thimphu">Asia/Thimphu (360)</option>
                  <option value="Indian/Chagos">Indian/Chagos (360)</option>
                  <option value="Asia/Katmandu">Asia/Katmandu (345)</option>
                  <option value="Asia/Colombo">Asia/Colombo (330)</option>
                  <option value="Antarctica/Mawson">
                    Antarctica/Mawson (300)
                  </option>
                  <option value="Asia/Aqtau">Asia/Aqtau (300)</option>
                  <option value="Asia/Aqtobe">Asia/Aqtobe (300)</option>
                  <option value="Asia/Ashgabat">Asia/Ashgabat (300)</option>
                  <option value="Asia/Dushanbe">Asia/Dushanbe (300)</option>
                  <option value="Asia/Karachi">Asia/Karachi (300)</option>
                  <option value="Asia/Tashkent">Asia/Tashkent (300)</option>
                  <option value="Asia/Yekaterinburg">
                    Asia/Yekaterinburg (300)
                  </option>
                  <option value="Indian/Kerguelen">
                    Indian/Kerguelen (300)
                  </option>
                  <option value="Indian/Maldives">Indian/Maldives (300)</option>
                  <option value="Asia/Kabul">Asia/Kabul (270)</option>
                  <option value="Asia/Baku">Asia/Baku (240)</option>
                  <option value="Asia/Dubai">Asia/Dubai (240)</option>
                  <option value="Asia/Tbilisi">Asia/Tbilisi (240)</option>
                  <option value="Asia/Yerevan">Asia/Yerevan (240)</option>
                  <option value="Europe/Samara">Europe/Samara (240)</option>
                  <option value="Indian/Mahe">Indian/Mahe (240)</option>
                  <option value="Indian/Mauritius">
                    Indian/Mauritius (240)
                  </option>
                  <option value="Indian/Reunion">Indian/Reunion (240)</option>
                  <option value="Asia/Tehran">Asia/Tehran (210)</option>
                  <option value="Africa/Khartoum">Africa/Khartoum (180)</option>
                  <option value="Africa/Nairobi">Africa/Nairobi (180)</option>
                  <option value="Antarctica/Syowa">
                    Antarctica/Syowa (180)
                  </option>
                  <option value="Asia/Baghdad">Asia/Baghdad (180)</option>
                  <option value="Asia/Qatar">Asia/Qatar (180)</option>
                  <option value="Asia/Riyadh">Asia/Riyadh (180)</option>
                  <option value="Europe/Minsk">Europe/Minsk (180)</option>
                  <option value="Europe/Moscow">Europe/Moscow (180)</option>
                  <option value="Africa/Windhoek">Africa/Windhoek (120)</option>
                  <option value="Africa/Cairo">Africa/Cairo (120)</option>
                  <option value="Africa/Johannesburg">
                    Africa/Johannesburg (120)
                  </option>
                  <option value="Africa/Maputo">Africa/Maputo (120)</option>
                  <option value="Africa/Tripoli">Africa/Tripoli (120)</option>
                  <option value="Asia/Amman">Asia/Amman (120)</option>
                  <option value="Asia/Beirut">Asia/Beirut (120)</option>
                  <option value="Asia/Damascus">Asia/Damascus (120)</option>
                  <option value="Asia/Gaza">Asia/Gaza (120)</option>
                  <option value="Asia/Jerusalem">Asia/Jerusalem (120)</option>
                  <option value="Asia/Nicosia">Asia/Nicosia (120)</option>
                  <option value="Europe/Athens">Europe/Athens (120)</option>
                  <option value="Europe/Bucharest">
                    Europe/Bucharest (120)
                  </option>
                  <option value="Europe/Chisinau">Europe/Chisinau (120)</option>
                  <option value="Europe/Helsinki">Europe/Helsinki (120)</option>
                  <option value="Europe/Istanbul">Europe/Istanbul (120)</option>
                  <option value="Europe/Kaliningrad">
                    Europe/Kaliningrad (120)
                  </option>
                  <option value="Europe/Kiev">Europe/Kiev (120)</option>
                  <option value="Europe/Riga">Europe/Riga (120)</option>
                  <option value="Europe/Sofia">Europe/Sofia (120)</option>
                  <option value="Europe/Tallinn">Europe/Tallinn (120)</option>
                  <option value="Europe/Vilnius">Europe/Vilnius (120)</option>
                  <option value="Africa/Algiers">Africa/Algiers (60)</option>
                  <option value="Africa/Ceuta">Africa/Ceuta (60)</option>
                  <option value="Africa/Lagos">Africa/Lagos (60)</option>
                  <option value="Africa/Ndjamena">Africa/Ndjamena (60)</option>
                  <option value="Africa/Tunis">Africa/Tunis (60)</option>
                  <option value="Europe/Amsterdam">
                    Europe/Amsterdam (60)
                  </option>
                  <option value="Europe/Andorra">Europe/Andorra (60)</option>
                  <option value="Europe/Belgrade">Europe/Belgrade (60)</option>
                  <option value="Europe/Berlin">Europe/Berlin (60)</option>
                  <option value="Europe/Brussels">Europe/Brussels (60)</option>
                  <option value="Europe/Budapest">Europe/Budapest (60)</option>
                  <option value="Europe/Copenhagen">
                    Europe/Copenhagen (60)
                  </option>
                  <option value="Europe/Gibraltar">
                    Europe/Gibraltar (60)
                  </option>
                  <option value="Europe/Luxembourg">
                    Europe/Luxembourg (60)
                  </option>
                  <option value="Europe/Madrid">Europe/Madrid (60)</option>
                  <option value="Europe/Malta">Europe/Malta (60)</option>
                  <option value="Europe/Monaco">Europe/Monaco (60)</option>
                  <option value="Europe/Oslo">Europe/Oslo (60)</option>
                  <option value="Europe/Paris">Europe/Paris (60)</option>
                  <option value="Europe/Prague">Europe/Prague (60)</option>
                  <option value="Europe/Rome">Europe/Rome (60)</option>
                  <option value="Europe/Stockholm">
                    Europe/Stockholm (60)
                  </option>
                  <option value="Europe/Tirane">Europe/Tirane (60)</option>
                  <option value="Europe/Vienna">Europe/Vienna (60)</option>
                  <option value="Europe/Warsaw">Europe/Warsaw (60)</option>
                  <option value="Europe/Zurich">Europe/Zurich (60)</option>
                  <option value="Africa/Abidjan">Africa/Abidjan (0)</option>
                  <option value="Africa/Accra">Africa/Accra (0)</option>
                  <option value="Africa/Bissau">Africa/Bissau (0)</option>
                  <option value="Africa/Casablanca">
                    Africa/Casablanca (0)
                  </option>
                  <option value="Africa/El_Aaiun">Africa/El_Aaiun (0)</option>
                  <option value="Africa/Monrovia">Africa/Monrovia (0)</option>
                  <option value="America/Danmarkshavn">
                    America/Danmarkshavn (0)
                  </option>
                  <option value="Atlantic/Canary">Atlantic/Canary (0)</option>
                  <option value="Atlantic/Faroe">Atlantic/Faroe (0)</option>
                  <option value="Atlantic/Reykjavik">
                    Atlantic/Reykjavik (0)
                  </option>
                  <option value="Etc/GMT">Etc/GMT (0)</option>
                  <option value="Europe/Dublin">Europe/Dublin (0)</option>
                  <option value="Europe/Lisbon">Europe/Lisbon (0)</option>
                  <option value="Europe/London">Europe/London (0)</option>
                  <option value="America/Scoresbysund">
                    America/Scoresbysund (-60)
                  </option>
                  <option value="Atlantic/Azores">Atlantic/Azores (-60)</option>
                  <option value="Atlantic/Cape_Verde">
                    Atlantic/Cape_Verde (-60)
                  </option>
                  <option value="America/Sao_Paulo">
                    America/Sao_Paulo (-120)
                  </option>
                  <option value="America/Noronha">
                    America/Noronha (-120)
                  </option>
                  <option value="Atlantic/South_Georgia">
                    Atlantic/South_Georgia (-120)
                  </option>
                  <option value="America/St_Johns">
                    America/St_Johns (-150)
                  </option>
                  <option value="America/Asuncion">
                    America/Asuncion (-180)
                  </option>
                  <option value="America/Campo_Grande">
                    America/Campo_Grande (-180)
                  </option>
                  <option value="America/Cuiaba">America/Cuiaba (-180)</option>
                  <option value="America/Araguaina">
                    America/Araguaina (-180)
                  </option>
                  <option value="America/Argentina/Buenos_Aires">
                    America/Argentina/Buenos_Aires (-180)
                  </option>
                  <option value="America/Bahia">America/Bahia (-180)</option>
                  <option value="America/Belem">America/Belem (-180)</option>
                  <option value="America/Cayenne">
                    America/Cayenne (-180)
                  </option>
                  <option value="America/Fortaleza">
                    America/Fortaleza (-180)
                  </option>
                  <option value="America/Godthab">
                    America/Godthab (-180)
                  </option>
                  <option value="America/Maceio">America/Maceio (-180)</option>
                  <option value="America/Miquelon">
                    America/Miquelon (-180)
                  </option>
                  <option value="America/Montevideo">
                    America/Montevideo (-180)
                  </option>
                  <option value="America/Paramaribo">
                    America/Paramaribo (-180)
                  </option>
                  <option value="America/Recife">America/Recife (-180)</option>
                  <option value="America/Santiago">
                    America/Santiago (-180)
                  </option>
                  <option value="Antarctica/Palmer">
                    Antarctica/Palmer (-180)
                  </option>
                  <option value="Antarctica/Rothera">
                    Antarctica/Rothera (-180)
                  </option>
                  <option value="Atlantic/Stanley">
                    Atlantic/Stanley (-180)
                  </option>
                  <option value="America/Caracas">
                    America/Caracas (-210)
                  </option>
                  <option value="America/Barbados">
                    America/Barbados (-240)
                  </option>
                  <option value="America/Boa_Vista">
                    America/Boa_Vista (-240)
                  </option>
                  <option value="America/Curacao">
                    America/Curacao (-240)
                  </option>
                  <option value="America/Grand_Turk">
                    America/Grand_Turk (-240)
                  </option>
                  <option value="America/Guyana">America/Guyana (-240)</option>
                  <option value="America/Halifax">
                    America/Halifax (-240)
                  </option>
                  <option value="America/La_Paz">America/La_Paz (-240)</option>
                  <option value="America/Manaus">America/Manaus (-240)</option>
                  <option value="America/Martinique">
                    America/Martinique (-240)
                  </option>
                  <option value="America/Port_of_Spain">
                    America/Port_of_Spain (-240)
                  </option>
                  <option value="America/Porto_Velho">
                    America/Porto_Velho (-240)
                  </option>
                  <option value="America/Puerto_Rico">
                    America/Puerto_Rico (-240)
                  </option>
                  <option value="America/Santo_Domingo">
                    America/Santo_Domingo (-240)
                  </option>
                  <option value="America/Thule">America/Thule (-240)</option>
                  <option value="Atlantic/Bermuda">
                    Atlantic/Bermuda (-240)
                  </option>
                  <option value="America/Bogota">America/Bogota (-300)</option>
                  <option value="America/Cancun">America/Cancun (-300)</option>
                  <option value="America/Cayman">America/Cayman (-300)</option>
                  <option value="America/Guayaquil">
                    America/Guayaquil (-300)
                  </option>
                  <option value="America/Havana">America/Havana (-300)</option>
                  <option value="America/Iqaluit">
                    America/Iqaluit (-300)
                  </option>
                  <option value="America/Jamaica">
                    America/Jamaica (-300)
                  </option>
                  <option value="America/Lima">America/Lima (-300)</option>
                  <option value="America/Nassau">America/Nassau (-300)</option>
                  <option value="America/New_York">
                    America/New_York (-300)
                  </option>
                  <option value="America/Panama">America/Panama (-300)</option>
                  <option value="America/Port-au-Prince">
                    America/Port-au-Prince (-300)
                  </option>
                  <option value="America/Rio_Branco">
                    America/Rio_Branco (-300)
                  </option>
                  <option value="America/Toronto">
                    America/Toronto (-300)
                  </option>
                  <option value="Pacific/Easter">Pacific/Easter (-300)</option>
                  <option value="America/Belize">America/Belize (-360)</option>
                  <option value="America/Chicago">
                    America/Chicago (-360)
                  </option>
                  <option value="America/Costa_Rica">
                    America/Costa_Rica (-360)
                  </option>
                  <option value="America/El_Salvador">
                    America/El_Salvador (-360)
                  </option>
                  <option value="America/Guatemala">
                    America/Guatemala (-360)
                  </option>
                  <option value="America/Managua">
                    America/Managua (-360)
                  </option>
                  <option value="America/Mexico_City">
                    America/Mexico_City (-360)
                  </option>
                  <option value="America/Regina">America/Regina (-360)</option>
                  <option value="America/Tegucigalpa">
                    America/Tegucigalpa (-360)
                  </option>
                  <option value="America/Winnipeg">
                    America/Winnipeg (-360)
                  </option>
                  <option value="Pacific/Galapagos">
                    Pacific/Galapagos (-360)
                  </option>
                  <option value="America/Dawson_Creek">
                    America/Dawson_Creek (-420)
                  </option>
                  <option value="America/Denver">America/Denver (-420)</option>
                  <option value="America/Edmonton">
                    America/Edmonton (-420)
                  </option>
                  <option value="America/Hermosillo">
                    America/Hermosillo (-420)
                  </option>
                  <option value="America/Mazatlan">
                    America/Mazatlan (-420)
                  </option>
                  <option value="America/Phoenix">
                    America/Phoenix (-420)
                  </option>
                  <option value="America/Yellowknife">
                    America/Yellowknife (-420)
                  </option>
                  <option value="America/Los_Angeles">
                    America/Los_Angeles (-480)
                  </option>
                  <option value="America/Tijuana">
                    America/Tijuana (-480)
                  </option>
                  <option value="America/Vancouver">
                    America/Vancouver (-480)
                  </option>
                  <option value="America/Whitehorse">
                    America/Whitehorse (-480)
                  </option>
                  <option value="Pacific/Pitcairn">
                    Pacific/Pitcairn (-480)
                  </option>
                  <option value="Pacific/Marquesas">
                    Pacific/Marquesas (-510)
                  </option>
                  <option value="America/Anchorage">
                    America/Anchorage (-540)
                  </option>
                  <option value="Pacific/Gambier">
                    Pacific/Gambier (-540)
                  </option>
                  <option value="Pacific/Honolulu">
                    Pacific/Honolulu (-600)
                  </option>
                  <option value="Pacific/Rarotonga">
                    Pacific/Rarotonga (-600)
                  </option>
                  <option value="Pacific/Tahiti">Pacific/Tahiti (-600)</option>
                  <option value="Pacific/Niue">Pacific/Niue (-660)</option>
                  <option value="Pacific/Pago_Pago">
                    Pacific/Pago_Pago (-660)
                  </option>
                </Input>
                <br />
              </div>

              <div>
                <Label for="assignmentSubmissionDate">
                  Select Start Date
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                </Label>
                <Input
                  name="Select Start Date"
                  type="date"
                  id="Select Start Date"
                  min="1"
                  step="1"
                  placeholder="Select Start Date"
                  value={startDt}
                  onChange={handleStartDate}
                />
              </div>
              <br></br>

              <div className="form-group">
                <div>
                  <Label for="assignmentSubmissionTime">
                    Select Start Time
                  </Label>
                  <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    <div>
                      <Label for="hour">
                        {' '}
                        Hour
                        <span style={{ color: 'red', fontSize: '18px' }}>
                          *
                        </span>
                      </Label>
                      <Select
                        // style={{ minWidth: '5rem' }}
                        style={{ width: '100%', height: '40px', borderRadius: 4, border: '0.75px rgba(33, 42, 57, 0.25) solid' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={startHour}
                        onChange={(e) => {
                          e.stopPropagation()
                          handleStartHour(e)
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        {[...Array(24)].map((e, i) => {
                          let istr = i.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })
                          return <MenuItem value={istr}>{istr}</MenuItem>
                        })}
                      </Select>
                    </div>
                    <div>
                      <Label for="minute">
                        Minutes
                        <span style={{ color: 'red', fontSize: '18px' }}>
                          *
                        </span>
                      </Label>
                      <Select
                        style={{ width: '100%', height: '40px', borderRadius: 4, border: '0.75px rgba(33, 42, 57, 0.25) solid' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={startMin}
                        onChange={(e) => {
                          e.stopPropagation()
                          handleStartMin(e)
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        {[...Array(60)].map((e, i) => {
                          let istr = i.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })
                          return <MenuItem value={istr}>{istr}</MenuItem>
                        })}
                      </Select>
                    </div>
                    <div></div>
                    <div></div>

                  </div>
                </div>
              </div>
              <div>

                <div>
                  <Label for="assignmentSubmissionDate">
                    Select End Date
                    <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                  </Label>
                  <Input
                    name="assignmentSubmissionDate"
                    type="date"
                    id="assignmentSubmissionDate"
                    min="1"
                    step="1"
                    placeholder="Enter Assignment Deadline"
                    value={endDate}
                    onChange={handleEndDate}
                  />
                </div>
                <br></br>
                <Label for="assignmentSubmissionTime">Select End Time</Label>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                  <div>
                    <Label for="hour">
                      {' '}
                      Hour
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </Label>
                    <Select
                      style={{ width: '100%', height: '40px', borderRadius: 4, border: '0.75px rgba(33, 42, 57, 0.25) solid' }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={endHour}
                      onChange={(e) => {
                        e.stopPropagation()
                        handleEndHour(e)
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      {[...Array(24)].map((e, i) => {
                        let istr = i.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })
                        // console.log({istr})
                        return <MenuItem value={istr}>{istr}</MenuItem>
                      })}
                    </Select>
                  </div>
                  <div >
                    <Label for="minute">
                      Minutes
                      <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    </Label>
                    <Select
                      style={{ width: '100%', height: '40px', borderRadius: 4, border: '0.75px rgba(33, 42, 57, 0.25) solid' }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={endMin}
                      onChange={(e) => {
                        e.stopPropagation()
                        handleEndMin(e)
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      {[...Array(60)].map((e, i) => {
                        let istr = i.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })
                        // console.log({istr})
                        return <MenuItem value={istr}>{istr}</MenuItem>
                      })}
                    </Select>
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>}
            <br></br>
            Note : People who have filled the feedback once, won't be getting a reminder to fill it again
          </Modal.Content>
          <ModalFooter>

            <Button color="success" onClick={handleSave}>
              Save
            </Button>
            <Button
              color="danger"
              onClick={(e) => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {loading && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
    </div>
  )
}

export default ReschedulingFdbkForms
