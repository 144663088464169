import React, { useEffect, useState } from 'react'
import './FeedbackDashboard.scss'
import FeedbackDashboard from './FeedbackDashboard'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import PreviewFeedback from './PreviewFeedback.jsx'

import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Modal } from 'semantic-ui-react'
import { ModalFooter } from 'reactstrap'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Autocomplete,
  TextField,
  Checkbox
} from '@mui/material'
import { IconButton, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import {
  startFetchCourse,
  startFetchAddedFeebackInCoursePage,
  DeleteFeebackInCoursePage,
  startFetchFeedbackScale,
  fetchFeedbackSection
} from '../../actions/courseAction.js'
import Button from '@mui/material/Button'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import moment from 'moment'
import { Loader, Segment } from 'semantic-ui-react'
import FeedbackClone from './FeedbackClone.jsx'
import { Link, Redirect } from 'react-router-dom'
import { history } from '../../index.js'

function ManageFeedbackForm() {
  const [coursePage, setCoursePage] = useState([])
  const [coursePageObj, setCoursePageObj] = useState({})

  const [coursePageName, setCoursePageName] = useState('')
  const [testList, setTestList] = useState([])
  const [clonemodalOpen, setCloneModalOpen] = useState(false)
  const [liveStatusmodalOpen, setLiveStatusModalOpen] = useState(false)
  const [liveStatusIndex, setLiveStatusIndex] = useState()
  const [originalFeedbackList, setOriginalFeedbackList] = useState([])
  const [showPreviewFeedback, setShowPreviewFeedback] = useState(false)
  const [sectionArr, setSectionArr] = useState([])
  const [answerTemplateNameMap, setAnswerTemplateNameMap] = useState({})
  const [batchNameMap, setBatchNameMap] = useState({})


  const [searchQuery, setSearchQuery] = useState('')
  let filteredData = []

  console.log({ showPreviewFeedback })

  const onSearchNameInputChange = (e) => {
    let searchquery = e.target.value
    setSearchQuery(searchquery)
    console.log(searchquery, originalFeedbackList)

    if (e.target.value && e.target.value.length == 0) {
      setTestList([...originalFeedbackList])
      return
    } else {
      let filter_data = originalFeedbackList.filter((test) => test.name.toLowerCase().indexOf(e.target.value.toLowerCase()) != -1)
      setTestList([...filter_data])
    }
  }


  // const[modalOpen, setModalOpen] = useState(false)


  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ; (async () => {
      await getCourse()
    })()
  }, [])

  let clonehandleAction = () => {
    setCloneModalOpen(!clonemodalOpen)
  }

  let getCourse = async () => {
    setLoading(true)
    let data = await startFetchCourse()
    console.log(data.data.courses)
    if (data.data && data.data.courses) {
      setCoursePage([...data.data.courses])
    }
    setLoading(false)
  }
  let courseChanged = async (crs_pg_id) => {
    setLoading(true)

    let course_page_obj = coursePage.filter((crs_pg) => crs_pg._id == crs_pg_id)
    console.log({ course_page_obj })

    if (course_page_obj[0]) {
      setCoursePageObj({ ...course_page_obj[0] })
      setCoursePageName(course_page_obj[0].name)
      let res = await startFetchAddedFeebackInCoursePage(crs_pg_id)

      console.log({ res })
      if (res.data.tests) {
        setTestList([...res.data.tests])
        setOriginalFeedbackList([...res.data.tests])
        setBatchNameMap({...res.data.batch_name_map})
      }
    }
    setLoading(false)
  }

  let handleShowPreviewFeeback = async (fdbk) => {
    setLoading(true)
    let res = await startFetchFeedbackScale()
    if (res.data && res.data.feedback_scale) {
      setAnswerTemplateNameMap({ ...res.data.answer_template_map })
    }
    let section = await fetchFeedbackSection(coursePageObj._id, fdbk._id)
    if (section && section.data && section.data.find_section) {
      setSectionArr([...section.data.find_section])
    }
    setShowPreviewFeedback(true)
    setLoading(false)
  }

  let handleDeleteFeedback = async (e, data) => {
    setLoading(true)
    if (data.noOfPrograms || data.noOfChapter) {
      alert("This feedback cannot be removed because it is used in live programmes.")
      setLoading(false)
      return

    }
    await DeleteFeebackInCoursePage(data._id, coursePageObj._id)
    let res = await startFetchAddedFeebackInCoursePage(coursePageObj._id)

    console.log({ res })
    if (res.data.tests) {
      setTestList([...res.data.tests])
      setOriginalFeedbackList([...res.data.tests])
    }
    setLoading(false)
  }

  const handleChange = (event) => {
    // setAge(event.target.value)
  }
  return (
    <div style={{ backgroundColor: 'rgba(37, 92, 168, 0.05)',minHeight: "100vh" }} className='feedback-management'>
      {!loading && (
        <div >
          <FeedbackDashboard />
          <div className="side-bar-tab-view">
            <div className="select-course-create-feeedback">
              <div className="form-group">
                <Autocomplete
                  className="select-course"
                  disableClearable
                  value={coursePageName ? coursePageName : 'Select a Course'}
                  onChange={(e, crs) => {
                    courseChanged(crs._id)
                  }}
                  getOptionLabel={(course) => {
                    return course.name || course
                  }}
                  getOptionSelected={(option, test) => option.name == test}
                  options={coursePage}
                  disabled={coursePage.length === 0 ? true : false}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={coursePage.length == 0 ? 'Loading...' : ''}
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
              </div>
              <Button
                variant="contained"
                sx={{ textTransform: 'none' }}
                style={{ backgroundColor: '#035642', borderColor: '4px' }}
                onClick={() => {
                  history.push('/feedback-management/create-feedback-form')
                }}
              >
                {' '}
                {/* + Create New Feedback */}
                <span><img src="https://cdn.pegasus.imarticus.org/feedback/ic_round-plus (1).svg"></img>Create/Edit Feedback Form</span>
              </Button>
            </div>

            {liveStatusmodalOpen && <Modal
              style={{
                height: 'fit-content',
                margin: 'auto',
                marginTop: 'auto',
                width: '738px',
              }}
              open={liveStatusmodalOpen}
            >
              <Modal.Header>Live Status</Modal.Header>
              <Modal.Content
                style={{
                  maxHeight: 'calc(100vh - 210px)',
                  overflowY: 'auto',
                }}
              >
                <FormControl>

                  <table class="table table-bordered">
                    <thead>
                      <tr className='tr-layout'>
                        <th scope="col" className="table-header">S. No.</th>

                        <th scope="col" className="table-header">
                          Course Name
                        </th>
                        <th scope="col" className="table-header">
                          Batch Name
                        </th>
                        <th scope="col" className="table-header">
                          Chapter 
                        </th>
                        <th scope="col" className="table-header">
                          Course Lecture 
                        </th>
                        <th scope="col" className="table-header">
                          Course Live Lecture
                        </th>
                        <th scope="col" className="table-header">
                          Survey Feedbacks
                        </th>

                      </tr>
                    </thead>

                    <tbody>
                      {testList[liveStatusIndex] && testList[liveStatusIndex].fdbk_status &&
                        Object.keys(testList[liveStatusIndex].fdbk_status).map((data, i) => {
                          return (
                            <tr key={i}>
                              <td className="table-cell">{i + 1}</td>
                              <td className="table-cell">{coursePageName}</td>
                              <td className="table-cell">{batchNameMap[data]}</td>
                              {/* {data.is_chapter ? <td className="table-cell">{data.nm}</td> : <td className="table-cell">-</td>}
                              {data.is_lec ? <td className="table-cell">{data.nm}</td> : <td className="table-cell">-</td>}
                              {data.is_liv_lec ? <td className="table-cell">{data.nm}</td> : <td className="table-cell">-</td>}
                              {data.is_survey_feedback ? <td className="table-cell">{data.nm}</td> : <td className="table-cell">-</td>} */}
                              {testList[liveStatusIndex].fdbk_status[data].chapter && testList[liveStatusIndex].fdbk_status[data].chapter.length > 0? <td className="table-cell">{testList[liveStatusIndex].fdbk_status[data].chapter.map((dt)=>dt.nm).join(' \n\n ')}</td>:<td className="table-cell">-</td>}
                              {testList[liveStatusIndex].fdbk_status[data].chapter && testList[liveStatusIndex].fdbk_status[data].lec.length > 0?  <td className="table-cell">{testList[liveStatusIndex].fdbk_status[data].lec.map((dt)=>dt.nm).join(' \n\n')}</td>:<td className="table-cell">-</td>}
                              {testList[liveStatusIndex].fdbk_status[data].chapter && testList[liveStatusIndex].fdbk_status[data].liv_lec.length > 0?  <td className="table-cell">{testList[liveStatusIndex].fdbk_status[data].liv_lec.map((dt)=>dt.nm).join(' \n\n ')}</td>:<td className="table-cell">-</td>}
                              {testList[liveStatusIndex].fdbk_status[data].chapter && testList[liveStatusIndex].fdbk_status[data].survey_fdbk.length > 0? <td className="table-cell">{testList[liveStatusIndex].fdbk_status[data].survey_fdbk.map((dt)=>dt.nm).join(' \n\n ')}</td>:<td className="table-cell">-</td>}

                            </tr>
                          )
                        })}
                    </tbody>
                  </table>

                </FormControl>

              </Modal.Content>
              <ModalFooter>
                <Button
                  style={{ backgroundColor: 'red', color: "white", borderColor: '4px' }}
                  onClick={(e) => {
                    setLiveStatusModalOpen(false)
                  }}
                >
                  Cancel
                </Button>

              </ModalFooter>
            </Modal>}

            <input
              placeholder="Search Feedback Forms"
              value={searchQuery}
              onChange={(e) => onSearchNameInputChange(e)}
              className="search-field  "
            />
            <hr></hr>
            <p className="table-header-text"  
            >All Feedback Forms   <Tooltip  
              title="All feedback forms related to the selected course are available here."
              arrow
            >
              <IconButton sx={{ m: -0.7 }}>
                <InfoIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip></p>
            <div  style={{width: '100%', height: '100%', background: 'white', borderRadius: 8, border: '1px rgba(60, 72, 82, 0.25) solid'}}>
              {testList && testList.length > 0 &&
                <table class="table table-bordered" >
                  <thead >
                    <tr  className='tr-layout'>
                      <th scope="col" className="table-header" style={{minWidth:"80px"}}>S. No.</th>
                      <th scope="col" className="table-header" style={{minWidth:"140px"}}>
                        Feedback Name
                      </th>
                      <th scope="col" className="table-header">
                        Course Name
                      </th>
                      <th scope="col" className="table-header">
                        Created On
                      </th>
                      <th scope="col" className="table-header">
                        Status
                      </th>
                      <th scope="col" className="table-header">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {testList &&
                      testList.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td className="table-cell">{i + 1}</td>
                            {/* <td className="table-cell"> {!data.is_old_feedback ? <Button onClick={() => { handleShowPreviewFeeback(data) }}> {data.name}</Button> : <>{data.name}</>} */}
                           {!data.is_old_feedback ? <td className="table-cell"> <Button  sx={{ textTransform: 'none' }} onClick={() => { handleShowPreviewFeeback(data) }}> {data.name}</Button></td> :<td className="table-cell"> {data.name}</td> }

                            
                            <td className="table-cell">{coursePageName}</td>
                            <td className="table-cell">
                              {' '}
                              {moment(data.createdAt).format(
                                'MMMM Do YYYY, h:mm:ss a'
                              )}
                            </td>
                            <td className="table-cell">
                              {data.noOfPrograms  > 0 ? <Button onClick={() => {
                                setLiveStatusModalOpen(!liveStatusmodalOpen)
                                setLiveStatusIndex(i)
                              }}
                              >  <div
                                style={{ display: 'flex', flexDirection: 'column' }}
                              >
                                  <div>Live in</div>
                                  <div>Batches - {data.noOfbatches ? data.noOfbatches : 0} </div>
                                  <div>Chapter - {data.noOfChapter ? data.noOfChapter : 0}  </div>
                                  <div>Lecture - {data.noOflec ? data.noOflec : 0}  </div>
                                  <div>Live Lecture - {data.noOflivlec ? data.noOflivlec : 0} </div>
                                  <div>Survey Feedback - {data.noOfsurveyfeedback ? data.noOfsurveyfeedback : 0} </div>

                                </div></Button> : '-'}
                            </td>
                            <td
                              className="table-cell"
                            // style={{
                            //   display: 'flex',
                            //   justifyContent: 'space-around',
                            // }}
                            >
                              {/* <Button  onClick={clonehandleAction}>
                          <ContentCopyOutlinedIcon/>
                          </Button> */}
                             {!data.is_old_feedback ?<FeedbackClone data={data} coursePageObj={coursePageObj} />: <p>Previously created feedback on the quiz admin panel cannot be cloned.</p>}
                              {/* <Button >
                            <EditOutlinedIcon />
                          </Button >
                          <Button onClick={(e) => handleDeleteFeedback(e, data)}>
                            <DeleteIcon style={{ color: 'red' }} />
                          </Button> */}
                              {/* {clonemodalOpen &&<FeedbackClone  modalOpen={clonemodalOpen} data = {data} />} */}

                            </td>

                          </tr>
                        )
                      })}
                  </tbody>
                </table>}
            </div>
            <br />
            <br />


            {testList && testList.length == 0 &&
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                <div >
                  <img src="https://cdn.pegasus.imarticus.org/feedback/empty.png"></img>
                </div>
                <div></div>
                <div>
                  <p className='empty-text-header'>Its empty here!</p>
                </div>
                <div></div>
                <div style={{ width: "333px" }}>
                  <p className='empty-text-desc'>Add Feedback Forms by clicking on the "Create/Edit Feedback Form” Button</p>
                </div>
              </div>
            }
            {showPreviewFeedback && <PreviewFeedback
              sectionArr={sectionArr}
              answerTemplateNameMap={answerTemplateNameMap}
              prevFeedbackTest={true}
              setShowPreviewFeedback={setShowPreviewFeedback}
            />}
          </div>


        </div>
      )
      }

      {
        loading && (
          <div className="Loader">
            <Loader active inline />
          </div>
        )
      }
    </div >
  )
}

{/* <Button color="link">
    link
  </Button> */}

export default ManageFeedbackForm
