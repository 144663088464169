import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { Label, Button, ModalFooter } from 'reactstrap'
import { Loader, Segment } from 'semantic-ui-react'
import {
  Autocomplete,
  TextField,
  Checkbox
} from '@mui/material'
import {
  startFetchCourse,
  startFetchAddedFeebackInCoursePage,
  cloneFeedback
} from '../../actions/courseAction.js'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import './FeedbackDashboard.scss'

function FeedbackClone(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [feedback, setFeedback] = useState({})
  const [coursePage, setCoursePage] = useState([])
  const [coursePageName, setCoursePageName] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedCrsPgId, setSelectedCrsPgId] = useState()


  console.log({ props })
  useEffect(() => {
    // setModalOpen(props.modalOpen)
    setFeedback({ ...props.data })
  }, [])

  useEffect(() => {
    ;(async () => {
      await getCourse()
    })()
  }, [])

  let getCourse = async () => {
    setLoading(true)
    let data = await startFetchCourse()
    console.log(data.data.courses && props.coursePageObj)
    if (data.data && data.data.courses) {
      setCoursePage([...data.data.courses])
    }
    setLoading(false)
  }
  let courseChanged = async (crs_pg_id) => {
    setSelectedCrsPgId(crs_pg_id)
    let course_page_obj = coursePage.filter((crs_pg) => crs_pg._id == crs_pg_id)
    console.log({ course_page_obj })
    if (course_page_obj[0]) {
      setCoursePageName(course_page_obj[0].name)
     
    }
  }

  let handleSave = async()=>{
    setLoading(true)

    console.log(selectedCrsPgId,props.coursePageObj._id)

    if(selectedCrsPgId == props.coursePageObj._id ){
      alert("Feedback can't be clone in the same course")
      setLoading(false)
      return

    }
    if(!selectedCrsPgId||  !props.coursePageObj._id){
      alert("Please select course name ")
      setLoading(false)
      return

    }

    let crs_pg_id = selectedCrsPgId
    let tid  = feedback._id

    let res = await cloneFeedback(crs_pg_id,tid)
    if ('statusCode' in res && res.statusCode == 403) {
      window.alert(res.error)
      setLoading(false)
      return
    }
    if (res.success) {
      window.alert('Saved Successfully')
      setLoading(false)
      setModalOpen(false)

    }
    if (!res.success) {
      if (res.message) {
        window.alert(res.message)
        setLoading(false)

      } else {
        window.alert('You have no permission to perform this action!')
        setLoading(false)

      }
    }




  }

  return (
    <div className='feedback-management'>
      <Button
        // color = "info"
        outline
        onClick={() => {
          setModalOpen(true)
        }}
        // disabled = {feedback.is_old_feedback}
      >
        {/* <ContentCopyOutlinedIcon /> */}
        <img src="https://cdn.pegasus.imarticus.org/feedback/clone.svg"></img>

      </Button>
      {!loading && <Modal
        style={{
          height: 'fit-content',
          margin: 'auto',
          marginTop: 'auto',
          width: '361px',
        }}
        open={modalOpen}
        className='feedback-management'

      >
        <Modal.Header>
        <div style={{display:"flex",justifyContent:"space-between"}}>
              <div>   Clone Feedback Form </div>
              <div  onClick={(e) => {
                setModalOpen(false)
              }} style={{cursor:"pointer"}}>×</div>
            </div>
        </Modal.Header>
        <Modal.Content
          style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto',
          }}
        >
          <div className="form-group">
            <p>Feedback Form</p>
            <select className="select-course" id="overrideSelectCourse" disabled style={{border:"1px solid black",color:"black",fontWeight:400,height:"48px"}}>
              <option className="dropdown-item " hidden>
                {feedback ? feedback.name : ''}
              </option>
            </select>
          </div>
          <div className="form-group">
            {/* <p>Select Course*</p> */}
            {/* <select
              className="select-course"
              id="overrideSelectCourse"
              onChange={(e) => courseChanged(e.target.value)}
            >
              <option className="dropdown-item " hidden>
                {coursePageName ? coursePageName : 'Select Course Name'}
              </option>
              {coursePage.map((crs, key) => (
                <option key={key} className="dropdown-item " value={crs._id}>
                  {crs.name}
                </option>
              ))}
            </select> */}
              <Autocomplete
                  className="select-course"
                  disableClearable
                  value={coursePageName ? coursePageName : 'Select a Course'}
                  onChange={(e, crs) => {
                    courseChanged(crs._id)
                  }}
                  getOptionLabel={(course) => {
                    return course.name || course
                  }}
                  getOptionSelected={(option, test) => option.name == test}
                  options={coursePage}
                  disabled={coursePage.length === 0 ? true : false}
                  renderInput={(params) => {
                    return (
                      <TextField
                        label={coursePage.length == 0 ? 'Loading...' : 'Select a Course'}
                        {...params}
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
                <br/>
            *Select the program you want to clone this feedback form to.
          </div>
        </Modal.Content>
        <ModalFooter>
         
          <Button
            // color="success"
            style={{ backgroundColor: '#055646' }}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            color="danger"
            onClick={(e) => {
              setModalOpen(false)
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>}
      {loading && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
    </div>
  )
}

export default FeedbackClone
