
import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { Label, Button, ModalFooter } from 'reactstrap'
import { Loader, Segment } from 'semantic-ui-react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  startFetchCourse,
  startFetchAddedFeebackInCoursePage,
  cloneFeedback,
  createFeedbackForm
} from '../../actions/courseAction.js'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import TextField from '@mui/material/TextField';
import './FeedbackDashboard.scss'

function AddFeedbackForm(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [feedback, setFeedback] = useState({})
  const [coursePage, setCoursePage] = useState([])
  const [coursePageName, setCoursePageName] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedCrsPgId, setSelectedCrsPgId] = useState()
  const [sectionType, setSectionType] = useState(1)

  const [feedbackName, setFeedbackName] = useState('')

  console.log({ props })
  useEffect(() => {
    // setModalOpen(props.modalOpen)
    setFeedback({ ...props.data })
  }, [])

  useEffect(() => {
    ; (async () => {
      await getCourse()
    })()
  }, [])

  let getCourse = async () => {
    setLoading(true)
    let data = await startFetchCourse()
    console.log(data.data.courses && props.coursePageObj)
    if (data.data && data.data.courses) {
      setCoursePage([...data.data.courses])
    }
    setLoading(false)
  }
  let courseChanged = async (crs_pg_id) => {
    setSelectedCrsPgId(crs_pg_id)
    let course_page_obj = coursePage.filter((crs_pg) => crs_pg._id == crs_pg_id)
    console.log({ course_page_obj })
    if (course_page_obj[0]) {
      setCoursePageName(course_page_obj[0].name)
    }
  }

  let handleSectionType = () => {
    if (sectionType == 1) {
      setSectionType(2)
    } else {
      setSectionType(1)
    }
  }

  let handleSave = async () => {
    setLoading(true)
    let fdbk_name = ""
    if(feedbackName){
       fdbk_name  = feedbackName.trim()
    }
      setFeedbackName(fdbk_name)
      
    console.log(selectedCrsPgId, props.coursePageObj._id)

    if (selectedCrsPgId == props.coursePageObj._id) {
      alert("Feedback can't be clone in the same course")
      setLoading(false)
      return
    }

    let crs_pg_id = selectedCrsPgId
    let tid = feedback._id
    if (fdbk_name == '' || !fdbk_name || fdbk_name == '.') {
      alert("Invalid Feedback Name")
      setLoading(false)
      return
    }


    let res = await createFeedbackForm(props.coursePageObj._id, fdbk_name)
    await props.handleFetchFeedbackForm()

    if ('statusCode' in res && res.statusCode == 403) {
      window.alert(res.error)
      setLoading(false)
      return
    }
    if (res.success) {
      window.alert('Saved Successfully')
      setLoading(false)
    }
    if (!res.success) {
      if (res.message) {
        window.alert(res.message)
      } else {
        window.alert('You have no permission to perform this action!')
      }
    }

    setLoading(false)
    setModalOpen(false)
  }

  return (
    <div className='feedback-management'>
      <Button
        // color="success"
        style={{ margin: "5px" }}
        className='icon-size'
        outline
        onClick={() => {
          setModalOpen(true)
        }}
      >
        {' '}
        <AddOutlinedIcon />
      </Button>

      {!loading && (
        <Modal
          style={{
            height: 'fit-content',
            margin: 'auto',
            marginTop: 'auto',
            width: '361px',
          }}
          open={modalOpen}
          className='feedback-management'

        >
          <Modal.Header>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Add Feedback Form </div>
              <div onClick={(e) => {
                setModalOpen(false)
              }} style={{ cursor: "pointer" }}>×</div>
            </div>
          </Modal.Header>
          <Modal.Content
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            <FormControl>
              {/* <FormLabel id="demo-row-radio-buttons-group-label" style={{color:"black"}}>
               Add Feedback Form Name
              </FormLabel> */}
              <TextField id="outlined-basic" label=" Add Feedback Form Name" variant="outlined" onChange={(e) => setFeedbackName(e.target.value)} />

            </FormControl>

          </Modal.Content>
          <ModalFooter>

            <Button style={{ backgroundColor: '#055646' }}
              onClick={handleSave}>
              Save
            </Button>
            <Button
              color="danger"
              onClick={(e) => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {loading && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
    </div>
  )
}

export default AddFeedbackForm
