
import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { Label, Button, ModalFooter } from 'reactstrap'
import { Loader, Segment } from 'semantic-ui-react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import {
  startFetchCourse,
  startFetchAddedFeebackInCoursePage,
  cloneFeedback,
  EditFeedbackName
} from '../../actions/courseAction.js'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import TextField from '@mui/material/TextField';

function EditFeedbackForm(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [feedbackName, setFeedbackName] = useState('')
  const [selectedFeedbackObj, setSelectedFeedbackObj] = useState({})
  const [loading, setLoading] = useState(false)
  const [selectedCrsPgId, setSelectedCrsPgId] = useState()

  console.log({ props })
  useEffect(() => {
    // setModalOpen(props.modalOpen)
    setFeedbackName(props.feedbackName)
    setSelectedFeedbackObj({ ...props.selectedFeedbackObj })
  }, [])





  let handleSave = async () => {
    setLoading(true)
    let fdbk_name = ''
    if(feedbackName){
       fdbk_name  = feedbackName.trim()
    }
    setFeedbackName(fdbk_name)

    if (fdbk_name == '' || !fdbk_name || fdbk_name == '.') {
      alert("Invalid Feedback Name")
      setLoading(false)
      return
    }
   
 
    let res = await EditFeedbackName(fdbk_name, selectedFeedbackObj)
    await props.handleFetchFeedbackForm()

    if ('statusCode' in res && res.statusCode == 403) {
      window.alert(res.error)
      setLoading(false)
      return
    }
    if (res.success) {
      window.alert('Feedback edited Successfully')
      setLoading(false)
    }
    if (!res.success) {
      if (res.message) {
        window.alert(res.message)
      } else {
        window.alert('You have no permission to perform this action!')
      }
    }

    setLoading(false)
    setModalOpen(false)
  }

  return (
    <div className='feedback-management'>
      <Button
        // color="success"
        outline
        style={{ margin: "5px" }}
        onClick={() => {
          setModalOpen(true)
        }}
        className='icon-size'

      >
        {' '}
        <img src="https://cdn.pegasus.imarticus.org/feedback/material-symbols_edit-outline.svg"></img>
      </Button>

      {!loading && (
        <Modal
          style={{
            height: 'fit-content',
            margin: 'auto',
            marginTop: 'auto',
            width: '361px',
          }}
          className='feedback-management'

          open={modalOpen}
        >
          <Modal.Header>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Edit Feedback Form </div>
              <div onClick={(e) => {
                setModalOpen(false)
              }} style={{ cursor: "pointer" }}>×</div>
            </div>
          </Modal.Header>
          <Modal.Content
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            <FormControl>
              <FormControl>
                {/* <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "black" }}>
                  Edit Feedback Form Name
                </FormLabel> */}

                <TextField id="outlined-basic" label="Edit Feedback Form Name" variant="outlined" value={feedbackName ? feedbackName : ''} onChange={(e) => setFeedbackName(e.target.value)} />

              </FormControl>
            </FormControl>

          </Modal.Content>
          <ModalFooter>

            <Button style={{ backgroundColor: '#055646' }}
              onClick={handleSave}>
              Save
            </Button>
            <Button
              color="danger"
              onClick={(e) => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {loading && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
    </div>
  )
}

export default EditFeedbackForm
