import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { Label, Button, ModalFooter } from 'reactstrap'
import { Loader, Segment } from 'semantic-ui-react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import './FeedbackDashboard.scss'

import {
  startFetchCourse,
  startFetchAddedFeebackInCoursePage,
  cloneFeedback,
  startFetchDynamicField,
  startCreateSection,
  EditFdbkSection
} from '../../actions/courseAction.js'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

function AddSection(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [feedback, setFeedback] = useState({})
  const [coursePage, setCoursePage] = useState([])
  const [coursePageName, setCoursePageName] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedCrsPgId, setSelectedCrsPgId] = useState()
  const [sectionType, setSectionType] = useState(1)
  const [dynamicFieldList, setDynamicFieldList] = useState([])
  const [dynamicField, setDynamicField] = useState('FACULTY')
  const [sectionList, setSectionList] = useState([])
  const [selectedSection, setSelectedSection] = useState('')
  const [sectionName, setSectionName] = useState('')
  const [editSection, setEditSection] = useState(false)
  const [sectionId, setSectionId] = useState()
  const [dynamicQuestionId, setDynamicQuestionId] = useState()


  const [dynamicFieldQuestionText, setDynamicFieldQuestionText] = useState('')

  console.log({ props })
  useEffect(() => {
    console.log({props})
    // setModalOpen(props.modalOpen)
    setFeedback({ ...props.data })
    setSelectedCrsPgId({})
    setEditSection(props.edit)
    setSectionName(props.section.name)
    setSectionId(props.section._id)
    if (props.dynamicFieldList) {
      setDynamicFieldList([...props.dynamicFieldList])
    }
    if (props.section.is_dynamic) {
      setSectionType(2)
      let dynamic_ques = props.section.ques.filter((ques) => ques.type == 6)
      if (dynamic_ques.length > 0) {
        setDynamicFieldQuestionText(dynamic_ques[0].text)
        setDynamicQuestionId(dynamic_ques[0]._id)

      }
      setDynamicField('FACULTY')
    } else {
      setSectionType(1)
    }
  }, [])


  let handleDynamicField = async (field) => {
    console.log({ field })
    setDynamicField(field)
  }

  let handleSectionType = () => {
    if (sectionType == 1) {
      setSectionType(2)
    } else {
      setSectionType(1)
    }
  }

  let handleSave = async () => {
    let crs_pg_id = feedback.fdk_crs_pg_id
    setLoading(true)
    let tid = feedback._id
    let is_dynamic = sectionType == 1 ? false : true
    let res
    let sec_name  = ''
    if(sectionName){
      sec_name =  sectionName.trim()
    }
    setSectionName(sec_name)
 
    console.log({dynamicField})
    let dynamic_dropdown_ques_text = ''
    if(dynamicFieldQuestionText){
       dynamic_dropdown_ques_text  =dynamicFieldQuestionText.trim()
    }
    setDynamicFieldQuestionText(dynamic_dropdown_ques_text)
    console.log({sec_name,sectionName})
    if(sec_name == '' ||(is_dynamic && dynamic_dropdown_ques_text == '')) {
      alert("Invalid Details !")
      setLoading(false)
      return
    }

    if (!sec_name || sec_name == '' || !crs_pg_id || !tid || sec_name == '.' ||(is_dynamic && dynamicFieldQuestionText == '.') ) {
      alert("Invalid Details")
      setLoading(false)
      return
    }
    if (is_dynamic && dynamicField == ''  ) {
      alert("Dynamic field can not be empty")
      setLoading(false)

      return
    }
    if (is_dynamic && dynamicFieldQuestionText == '') {
      alert("Dynamic question name can not be empty")
      setLoading(false)

      return
    }
 
    if (editSection) {

      res = await EditFdbkSection(
        tid,
        crs_pg_id,
        sec_name,
        is_dynamic,
        dynamicField,
        dynamicFieldQuestionText,
        sectionId,
        dynamicQuestionId,
      )
    } else {
      res = await startCreateSection(
        tid,
        crs_pg_id,
        sec_name,
        is_dynamic,
        dynamicField,
        dynamicFieldQuestionText
      )
    }

    if ('statusCode' in res && res.statusCode == 403) {
      window.alert(res.error)
      setLoading(false)
      return
    }
    if (res.success) {
      window.alert('Saved Successfully')
      setSectionName('')
      setDynamicFieldQuestionText('')

      setLoading(false)
    }
    if (!res.success) {
      if (res.message) {
        window.alert(res.message)
      } else {
        window.alert('You have no permission to perform this action!')
      }
    }
    props.handleSaveSec()
    setLoading(false)
    setModalOpen(false)
  }

  return (
    <div className='feedback-management'>
      {!editSection && <Button
        style={{ backgroundColor: '#055646' }}

        onClick={() => {
          setModalOpen(true)
        }}
      >
        {' '}
        <span>              <img src="https://cdn.pegasus.imarticus.org/feedback/ic_round-plus (1).svg"></img> Add Section       </span>
      </Button>}
      {editSection && <Button

        onClick={() => {
          setModalOpen(true)
        }}
        outline
      >
        {editSection ? <img src="https://cdn.pegasus.imarticus.org/feedback/material-symbols_edit-outline.svg"></img> :
          <img src="https://cdn.pegasus.imarticus.org/feedback/ic_round-plus.svg"></img>}

      </Button>}

      {!loading && (
        <Modal
          style={{
            height: 'fit-content',
            margin: 'auto',
            marginTop: 'auto',
            width: '361px',
          }}
          className='feedback-management'

          open={modalOpen}
        >
          <Modal.Header>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>   {!editSection ? 'Add Section' : 'Edit Section'} </div>
              <div onClick={(e) => {

                setModalOpen(false)
              }} style={{ cursor: "pointer" }}>×</div>
            </div>
          </Modal.Header>
          <Modal.Content
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{ color: 'black' }}
              >
                {!editSection ? 'Section Name' : 'Section Name'}
              </FormLabel>
              <TextField
                className="select-course"
                id="outlined-basic"
                label="Section Name"
                variant="outlined"
                value={sectionName}
                onChange={(e) => {
                  setSectionName(e.target.value)
                }}
              />
            </FormControl>
            <br></br>
            <br></br>

            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                style={{ color: 'black' }}
              >
                Choose Type of Section
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={sectionType}
                onClick={(e) => {
                  if (!editSection) {
                    handleSectionType(e)
                  }
                }}
                disabled={editSection}


              >
                <FormControlLabel
                  disabled={editSection}
                  value={1}
                  control={<Radio style={{ color: '#055646' }} disabled={editSection ? true : false} />}
                  label="Static"
                />
                <FormControlLabel
                  disabled={editSection}
                  value={2}
                  control={<Radio style={{ color: '#055646' }} disabled={editSection ? true : false} />}
                  label="Dynamic"
                />
              </RadioGroup>
            </FormControl>
            {sectionType == 2 && (
              <div className="form-group">
                <p>Choose Dynamic Field*</p>
                <select
                  className="select-course"
                  id="overrideSelectCourse"
                  onChange={(e) => handleDynamicField(e.target.value)}
                  disabled={editSection ? true : false}
                >
                  <option className="dropdown-item " hidden>
                    {dynamicField ? dynamicField : 'Select Dynamic Field'}
                  </option>
                  {dynamicFieldList.map((field, key) => (
                    <option
                      key={key}
                      className="dropdown-item "
                      value={field}
                    >
                      {field}
                    </option>
                  ))}
                </select>
                <br></br>
                <br></br>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{ color: 'black' }}
                  >
                    Dynamic Question Name
                  </FormLabel>
                  <TextField
                    className="select-course"
                    id="outlined-basic"
                    label="Dynamic Question Name"
                    variant="outlined"
                    value={dynamicFieldQuestionText}
                    onChange={(e) => {
                      setDynamicFieldQuestionText(e.target.value)
                    }}
                  />
                </FormControl>
                <br></br>
                <br></br>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  style={{ color: 'black' }}
                >
                  *By selecting this option this form will be dynamically linked
                  with all the numbers of Faculty.
                </FormLabel>
              </div>
            )}

          </Modal.Content>
          <ModalFooter>
         
            <Button style={{ backgroundColor: '#055646' }}
              onClick={handleSave}>
              Save
            </Button>
            <Button
              color="danger"
              onClick={(e) => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {loading && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
    </div>
  )
}

export default AddSection
