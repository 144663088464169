import React, { useEffect, useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { Label,Button, ModalFooter } from 'reactstrap'

import './FeedbackDashboard.scss'

import { Loader, Segment } from 'semantic-ui-react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {

  startCreateSection,
} from '../../actions/courseAction.js'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { StartRatingScale } from './StartRatingScale.jsx'
import NumericRatingScale from './NumericRatingScale.jsx'
import EmojiScaleRating from './EmojiScaleRating.jsx'

import NumericScale from './NumericScale.jsx'
import QualityScale from './QualityScale.jsx'
import { BorderColor } from '@mui/icons-material'

function PreviewFeedback(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [feedback, setFeedback] = useState({})
  const [coursePage, setCoursePage] = useState([])
  const [coursePageName, setCoursePageName] = useState()
  const [loading, setLoading] = useState(false)
  const [selectedCrsPgId, setSelectedCrsPgId] = useState()
  const [sectionType, setSectionType] = useState(1)
  const [dynamicField, setDynamicField] = useState('')
  const [sectionList, setSectionList] = useState([])
  const [selectedSection, setSelectedSection] = useState('')
  const [sectionName, setSectionName] = useState('')
  const [answerTemplateNameMap, setAnswerTemplateNameMap] = useState({})

  const [dynamicFieldQuestionText, setDynamicFieldQuestionText] = useState('')
  const [sectionArr, setSectionArr] = useState([])

  console.log({ props })
  useEffect(() => {
    // setModalOpen(props.modalOpen)
    setFeedback({ ...props.data })
    setSelectedCrsPgId({})
    setAnswerTemplateNameMap({ ...props.answerTemplateNameMap })
    setSectionArr([...props.sectionArr])
    if (props.prevFeedbackTest) {
      setModalOpen(true)
    }
    console.log(props.sectionArr)

  }, [])



  let handleDynamicField = async (field) => {
    console.log({ field })
    setDynamicField(field)
  }

  let handleSectionType = () => {
    if (sectionType == 1) {
      setSectionType(2)
    } else {
      setSectionType(1)
    }
  }

  let handleSave = async () => {
    setLoading(true)

    let crs_pg_id = feedback.fdk_crs_pg_id
    let tid = feedback._id
    let is_dynamic = sectionType == 1 ? false : true

    await startCreateSection(
      tid,
      crs_pg_id,
      sectionName,
      is_dynamic,
      dynamicField,
      dynamicFieldQuestionText
    )

    setLoading(false)
    setModalOpen(false)
  }

  return (
    <div className='feedback-management'>

      {!props.prevFeedbackTest && < Button className="save-btn-outline"
        onClick={() => {
          setModalOpen(true)
        }}
        color="success"
        outline
        // style={{ color: '#212A39',borderColor:'#212A39'}}
      >
        <span className="btn-text">Preview</span>
      </Button>}

      {!loading && (
        <Modal
          style={{
            height: 'fit-content',
            margin: 'auto',
            width: '605px',
            


          }}
          className='feedback-management'

          open={modalOpen}
        >
          <Modal.Header className="preview-feedback">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ margin: '20px', width: '100px', height: '100px' }}>
                {' '}
                <img
                  src={
                    'https://cdn.pegasus.imarticus.org/feedback/fdbk_header.png'
                  }
                  alt="png"
                //   style={{ width: 64, height: 64 }}
                />
              </div>
              <div
                style={{
                  margin: '2px',
                  color: '#FFFFFF',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <div>
                  {' '}
                  <p style={{ fontSize: '24px', fontWeight: 700 }}>
                    We’d welcome your feedback!
                  </p>
                </div>
                <div>
                  {' '}
                  <p style={{ fontSize: '16px', fontWeight: 400 }}>
                    Share your feedback and help us shape a better experience.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Content
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
              height: "600px",
              // overflowY:"auto"
            }}
          >
            <div>
              {props.sectionArr &&
                props.sectionArr.map((section, index) => {
                  return (
                    <>
                      { ((section.is_dynamic  && section.ques.length > 0 )|| (!section.is_dynamic  && section.ques.length > 0)) && <div key={index}>
                      <div
                        className="preview"
                        style={{
                          borderRadius: '12px',
                          backgroundColor: 'rgba(0, 111, 126, 0.15)',
                          height: '56px',
                          width: '525px',
                        }}
                      >
                        <div
                          className="preview-section-text"
                          style={{ margin: '16px' }}
                        >
                          {section.name ? section.name : '-'}
                        </div>
                        <div
                        // className="preview-text"
                        // style={{ marginRight: '10px' }}
                        ></div>
                      </div>
                      <div>
                        {section.ques &&
                          section.ques.map((ques, index) => {
                            return (
                              <div
                                // className="question-preview"
                                key={index}
                                style={{
                                  borderBottom: '3px solid rgba(0, 0, 0, 0.10)',
                                  margin: '40px',
                                }}
                              >
                                <div
                                  className="add-ques-sub-header"
                                //   style={{ margin: '40px' }}
                                >
                                  {index + 1} {ques.text ? ques.text : '-'}
                                </div>
                                <br></br>

                                {ques.type == 4 && (
                                  <div>
                                    {ques.feedback_scale &&
                                      ques.feedback_scale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      ques.feedback_scale.template_id
                                      ] == 'Numeric rating Scale' && (
                                        <NumericRatingScale
                                          answerSetOptionList={
                                            ques.feedback_scale.ansoptions
                                          }
                                        />
                                      )}
                                    {ques.feedback_scale &&
                                      ques.feedback_scale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      ques.feedback_scale.template_id
                                      ] == 'Numeric Scale' && (
                                        <NumericScale
                                          answerSetOptionList={
                                            ques.feedback_scale.ansoptions
                                          }
                                        />
                                      )}
                                    {ques.feedback_scale &&
                                      ques.feedback_scale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      ques.feedback_scale.template_id
                                      ] == 'Star rating Scale' && (
                                        <StartRatingScale
                                          answerSetOptionList={
                                            ques.feedback_scale.ansoptions
                                          }
                                        />
                                      )}
                                    {ques.feedback_scale &&
                                      ques.feedback_scale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      ques.feedback_scale.template_id
                                      ] == 'Emoji  rating Scale' && (
                                        <EmojiScaleRating
                                          answerSetOptionList={
                                            ques.feedback_scale.ansoptions
                                          }
                                        />
                                      )}
                                    {ques.feedback_scale &&
                                      ques.feedback_scale.ansoptions &&
                                      answerTemplateNameMap &&
                                      answerTemplateNameMap[
                                      ques.feedback_scale.template_id
                                      ] == 'Quality Scale' && (
                                        <QualityScale
                                          answerSetOptionList={
                                            ques.feedback_scale.ansoptions
                                          }
                                        />
                                      )}
                                    <br></br>
                                    <br></br>

                                    <br></br>
                                  </div>
                                )}
                                {ques.type == 3 && (
                                  <div>
                                    {/* <TextField
                                      id="standard-basic"
                                      label="Type here"
                                      variant="standard"
                                      style={{ width: '100%' }}
                                    /> */}
                                    <TextField
                                      id="outlined-multiline-static"
                                      label="Type here"
                                      multiline
                                      rows={4}
                                      style={{ width: '100%' }}
                                    />
                                    <br></br>
                                    <br></br>

                                    <br></br>
                                  </div>
                                )}
                                {ques.type == 6 && (
                                  <div>
                                    <InputLabel id="demo-simple-select-helper-label">
                                      {ques.drodown_type}
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      //   value={age}
                                      label={ques.drodown_type}
                                      style={{ width: '100%' }}
                                    //   onChange={handleChange}
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      <MenuItem value={10}>Faculty 1</MenuItem>
                                      <MenuItem value={20}>Faculty 2</MenuItem>
                                      <MenuItem value={30}>Faculty 3</MenuItem>
                                      <MenuItem value={30}>Faculty 4</MenuItem>

                                    </Select>
                                    <br></br>
                                  </div>
                                )}
                              </div>
                            )
                          })}
                      </div>
                      <br></br>
                    </div>}
                      </>
                  
                  )
                })}
            </div>
          </Modal.Content>
          <ModalFooter>
            <Button
              color="danger"
              onClick={(e) => {
                setModalOpen(false)
                if (props.prevFeedbackTest) {
                  props.setShowPreviewFeedback(false)

                }
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {loading && (
        <div className="Loader">
          <Loader active inline />
        </div>
      )}
    </div>
  )
}

export default PreviewFeedback
